import React, { useState, useEffect } from "react";
import { View, Linking } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import {
  Layout,
  Button,
  Text,
  TextInput,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const { isDarkmode, setTheme } = useTheme();
  const [usersData, setUsersData] = useState(null);
  const [error, setError] = useState(null);

  const [newUserName, setNewUserName] = React.useState('');

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setRefresh(false);

    async function fetchDataDB() {
      try {        
        const { data: tradingview_users, error } = await supabase
        .from('tradingview_users')
        .select('*');
        console.log(tradingview_users);
        setUsersData(tradingview_users);
      } catch (error) {
        console.log(error);
        setError(error);
      }
    }

    fetchDataDB();
  }, [refresh]); // Empty dependency array means this runs once on mount


  const TradingviewUsers = supabase.channel('custom-all-channel')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: '_tradingview_users' },
    (payload) => {
      console.log(payload);
      setRefresh(true);
    }
  )
  .subscribe()
  console.log(TradingviewUsers);

  const Tradingview_UsersChange = supabase.channel('custom-all-channel')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: '_tradingview__users_change' },
    (payload) => {
      console.log(payload);
      setRefresh(true);
    }
  )
  .subscribe()
  console.log(Tradingview_UsersChange)



  function formatDate(dateString) {
    if (dateString == null) {
      return '';
    }
    const date = new Date(dateString);
    if (!isNaN(date)) {
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString(); // Months are 0-based
      let year = date.getFullYear();
    
      // Adding leading zeros if day or month is less than 10
      day = day.length < 2 ? '0' + day : day;
      month = month.length < 2 ? '0' + month : month;
    
      return `${month}/${day}/${year}`;
    }
    return '';
  }


  // const { data, error } = await supabase.functions.invoke('get-current-users', {
  //   body: { foo: 'bar' }
  // });
  // console.log(data);

  return (
    <Layout>
      <TopNav
        middleContent="Home"
        rightContent={
          <Ionicons
            name={isDarkmode ? "sunny" : "moon"}
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        rightAction={() => {
          if (isDarkmode) {
            setTheme("light");
          } else {
            setTheme("dark");
          }
        }}
      />
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Section style={{ marginTop: 20 }}>
          <SectionContent>
            <Text fontWeight="bold" size="lg" style={{textAlign: "left"}}>
              Current Users:
            </Text>
            <Text fontWeight="bold" style={{ textAlign: "left" }}>
            {usersData?.map(item => (
              <div key={item.id}>
                <img src={item.userpic} height="15"/> {item.username} {formatDate(item.expiration)} 
                  {item.id>0 && <Button style={{marginLeft: 5, width: 1, size:'sm', textStyle:{size:5}}} size="sm" text="Del" status="danger"  onPress={ async ()=>{
                  if (confirm(`Are you sure you want to remove ` + item.username)) {
                    const res = await supabase.functions.invoke('del-user', {
                      body: { username: item.username }
                    });
                    setRefresh(true);
                  }}
                  }/>}
                  {item.id == 0 && `(Adding)`}
              </div>
            )) }
            </Text>
          </SectionContent>
        </Section>
      </View>
      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
        >
        <Section>
          <SectionContent>
            <TextInput 
              placeholder="Enter name of user to add"
              value={newUserName}
              onChangeText={(val) => setNewUserName(val)}
            />
            <Button 
              style={{ marginTop: 10 }}
              text="Add User"
              status="info"
              onPress={async () => {
                setNewUserName('');
                const res = await supabase.functions.invoke('upsert-user', {
                  body: { username: newUserName }
                });
                setRefresh(true);
              }}
            />
          </SectionContent>
        </Section>
      </View>
      <View style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Section>
          <SectionContent>
            <Button
              status="danger"
              text="Logout"
              onPress={async () => {
                const { error } = await supabase.auth.signOut();
                if (!error) {
                  alert("Signed out!");
                }
                if (error) {
                  alert(error.message);
                }
              }}
              style={{
                marginTop: 10,
              }}
            />
          </SectionContent>
        </Section>
      </View>
    </Layout>
  );
}



// <Button
// style={{ marginTop: 10 }}
// text="Rapi UI Documentation"
// status="info"
// onPress={() => Linking.openURL("https://rapi-ui.kikiding.space/")}
// />
// <Button
// text="Go to second screen"
// onPress={() => {
//   navigation.navigate("SecondScreen");
// }}
// style={{
//   marginTop: 10,
// }}
// />