import React, { createContext, useState, useEffect } from 'react';
import { supabase } from '../initSupabase';
import { Session } from '@supabase/supabase-js';
type ContextProps = {
	user: null | boolean;
	session: Session | null;
};

const AuthContext = createContext<Partial<ContextProps>>({});

interface Props {
	children: React.ReactNode;
}

const AuthProvider = (props: Props) => {
	// user null = loading
	const [user, setUser] = useState<null | boolean>(null);
	const [session, setSession] = useState<Session | null>(null);

	useEffect(async () => {
		const getSession = await supabase.auth.getSession();
		console.log(getSession);
		setSession(getSession.data.session);
		setUser(getSession.data.session ? true : false);
		
		const { data: authListener } = await supabase.auth.onAuthStateChange(
			async (event, getSession) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(getSession);
				setUser(getSession ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

	return (
		<AuthContext.Provider
			value={{
				user,
				session,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
